* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.main {
  width: 100%;
  max-width: 30rem;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: url("./assets/images/pix-bg.png") 100% 100% no-repeat;
  background-size: 100%;
  mix-blend-mode: multiply;
}

.title {
  display: flex;
  justify-content: space-between;
  text-align: center;
  width: 22.125rem;
  height: 2.0625rem;
  /* margin: 0 auto; */
  margin-top: 0.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  color: #000000;
  line-height: 3rem;
  -webkit-background-clip: text;
}
.returnImg{
  width: 2rem;
  height: 2.0625rem;
  background: center no-repeat url('../public/return2.png');
  background-size: 80%;
}
.titleText{
  line-height: 2.0625rem;
}
.main-content {
  width: 19.375rem;
  height: 23.6875rem;
  margin: 0.5rem auto 0 auto;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(15px) brightness(0.8) saturate(0.8) opacity(0.9);
  border-radius: 0.9375rem;
  opacity: 1;
  mix-blend-mode: overlay;
  border: 2px solid rgba(255, 255, 255, 0.8);
  position: relative;
  z-index: 1;
}

.main-content::before {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  width: 19.375rem;
  height: 23.7rem;
  border-radius: 0.9375rem;
  border: 2px solid rgba(255, 255, 255, 0.8);
  z-index: -1;
}

.main-content .description {
  width: 17.875rem;
  height: 2.375rem;
  font-size: 0.875rem;
  font-family: Inter-Bold, Inter;
  text-align: center;
  font-weight: 700;
  color: #999999;
  line-height: 1.1875rem;
  -webkit-background-clip: text;
}

.main-content .codeBox {
  width: 11.875rem;
  height: 11.875rem;
  margin: 3.75rem auto 2.125rem auto;
}

.main-content .amount {
  text-align: center;
  font-size: 1.5rem;
  font-family: Inter-Bold, Inter;
  font-weight: 700;
  color: #000000;
  line-height: 1.375rem;
  margin-bottom: 0.75rem;
  -webkit-background-clip: text;
}

.main-content .expireAt {
  font-size: 1rem;
  text-align: center;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: #323232;
  line-height: 1.375rem;
  -webkit-background-clip: text;
}

.tips {
  width: 20.875rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.tips div {
  font-size: 0.9375rem;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #4c4c4c;
  line-height: 1.1875rem;
  -webkit-background-clip: text;
}

.footer {
  width: 100%;
  text-align: center;
  margin: 1.5rem auto 0.25rem auto;
}

.footer .button {
  width: 18.4375rem;
  height: 3.125rem;
  background: rgba(30, 35, 136, 0.9);
  border-radius: 0.875rem;
  border-color: transparent;
  opacity: 1;
  font-size: 1.0625rem;
  font-family: Inter-Bold, Inter;
  font-weight: 700;
  color: #ffffff;
  line-height: 1.375rem;
}

.Loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal .mask {
  height: 100%;
  width: 100%;
  background-color: #000000;
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0.3;
  z-index: 1;
}

.modal .modal-container {
  z-index: 2;
  border-radius: 0.9375rem;
  background-color: #ffffff;
  padding: 1.875rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;
  font-size: 1rem;
  width: 320px;
}

.modal .modal-container .title {
  width: 100%;
  text-align: center;
  font-family: Inter;
  font-size: 1.2rem;
  font-weight: bold;
}

.modal .modal-container .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.modal .footer {
  padding: 0;
  width: 80%;
}

.modal button {
  background-color: #1c2449;
  color: #ffffff;
  border-radius: 5px;
  font-weight: bold;
  width: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
  border: 0;
  font-size: 16px;
}

.modal button:focus-visible {
  outline: 0;
}
